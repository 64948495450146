import React from 'react';
import '../css/Footer.css'

import linkedin from '../img/Linkedin.png'

export function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className='footer'>
            
            <p>
                <a href='/' style={{textDecoration:'none', color:'white'}}>&copy; Resume Urls {currentYear}</a>
                <br/>
                <span style={{fontSize:'smaller', fontWeight:'400'}}>by Ofri Yehuda</span>
            </p>

            <a href="https://www.linkedin.com/in/ofri-yehuda/" target="_blank" rel="noopener noreferrer">
                <img alt='linkedin' src={linkedin}  style={{height: '30px', filter: 'invert(1)'}}/>
            </a>
        </footer>
    );
}
