import { useEffect, useState } from 'react';
import { auth } from '../config/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Navigate } from 'react-router-dom';
import '../css/Login.css'
import login from '../img/Google.png'

export const Login = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                localStorage.setItem('isLoggedIn', 'true');
                setIsLoggedIn(true);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();

        try {
            await signInWithPopup(auth, provider);
            console.log('Successfully signed in with Google!');
        } catch (error) {
            console.error('Error during Google sign-in:', error);
        }
    };

    if (localStorage.getItem('isLoggedIn') || isLoggedIn) {
        return <Navigate to="/profile" />;
    }

    return (
        <div className='defult log_page'>
            <div className='login_cont'>
                <button onClick={handleGoogleLogin} className='login'>
                    <img src={login} alt='login wiyh Google'/>
                    Login with Google</button>
                    <span className='tag_line'>
                        Build your online resume now
                    </span>
            </div>
        </div>
    );
};
