import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Arrow } from './arrow.js'
import '../css/PersonalWebsite.css';

import email from '../img/Email.png';
import phone from '../img/Phone.png';
import resume from '../img/Resume.png';
import linkedin from '../img/Linkedin.png';
import { Loading } from './Loading.js';

export function PersonalWebsite({ setShowNav, setShowFooter }) {
  const { url } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('body_personal');

    // Cleanup function to remove the class when the component is unmounted or when the effect reruns
    return () => {
        document.body.classList.remove('body_personal');
    }
  }, []);

  useEffect(() => {
    // When the component mounts, hide the navigation
    setShowNav(false);

    // When the component unmounts, show the navigation again
    return () => setShowNav(true);
  }, [setShowNav]);

  useEffect(() => {
    setShowFooter(false);
    const fetchUserData = async () => {
      const db = getFirestore();
      const usersCollection = collection(db, "users");
      const querySnapshot = await getDocs(usersCollection);
  
      const userDataArray = [];
  
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.url && url && userData.url.toLowerCase() === url.toLowerCase()) {
          userDataArray.push(userData);
        }
      });
  
      if (userDataArray.length > 0) {
        // Assuming you want to work with the first matching user data
        const fetchedData = userDataArray[0];
        setUserData(fetchedData);
        setTimeout(() => {
          setLoading(false);
          setShowFooter(true);
        }, 2000); 

        if(fetchedData.selectedBackgroundColor) {
          // Update the body's background color with the fetched value
          document.body.style.backgroundImage = getComputedStyle(document.documentElement).getPropertyValue(fetchedData.selectedBackgroundColor);
        }

        if(fetchedData.selectedTextColor) {
          // Update the body's text color with the fetched value
          document.body.style.color = getComputedStyle(document.documentElement).getPropertyValue(fetchedData.selectedTextColor);
        }
      } else {
        console.log("User data not found for URL:", url);
        navigate('/404');
      }
    };
  
    fetchUserData();
  }, [url]);

  // Helper function to get the next section
  const getNextSection = (currentSection) => {
    const sectionsOrder = ["experience", "education", "skills", "projects", "volunteering"];
    const currentIndex = sectionsOrder.indexOf(currentSection);
    for (let i = currentIndex + 1; i < sectionsOrder.length; i++) {
      if (userData[sectionsOrder[i]] && userData[sectionsOrder[i]].length > 0) {
        return sectionsOrder[i];
      }
    }
    return null;
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="contact_wrap">
            <ul className='contact'>
              {userData.phoneNumber && (
                <li>
                  <a href={`tel:${userData.phoneNumber}`} target="_blank" rel="noopener noreferrer">
                    <img alt="phone" src={phone} />
                  </a>
                </li>
              )}
              {userData.email && (
                <li>
                  <a href={`mailto:${userData.email}`} target="_blank" rel="noopener noreferrer">
                    <img alt="email" src={email} />
                  </a>
                </li>
              )}
              {userData.linkedInLink && (
                <li>
                  <a href={userData.linkedInLink.startsWith('http') ? userData.linkedInLink : `https://${userData.linkedInLink}`} target="_blank" rel="noopener noreferrer">
                    <img alt="linkedin" src={linkedin} />
                  </a>
                </li>
              )}
            </ul>
          </div>

          <div id="main_head" className='main_head'>
            <h1 className='Headline_1'>Hi There,<br />my name is {userData.name}</h1>
            <h2 className='Headline_2'>{userData.description}</h2>
            <span className="arrow_span">Click here to <br />read more</span>
            <Arrow href="#experience" />
          </div>
          
          {userData.experiences && userData.experiences.length > 0 && (
            <div id="experience" className='experience'>
              <h1 className='title'>Experience</h1>
              {userData.experiences.map((exp, idx) => (
                <div key={idx}>
                  <h2>{exp.company}</h2>
                  <p>{exp.description}</p>
                </div>
              ))}
              {getNextSection("experience") && <Arrow href={`#${getNextSection("experience")}`} />}
            </div>
          )}

          {userData.education && userData.education.length > 0 && (
            <div id="education" className='education'>
              <h1 className='title'>Education</h1>
              {userData.education.map((edu, idx) => (
                <div key={idx}>
                  <h2>{edu.institute}</h2>
                  <p>{edu.description}</p>
                </div>
              ))}
              {getNextSection("education") && <Arrow href={`#${getNextSection("education")}`} />}
            </div>
          )}

          {userData.skills && userData.skills.length > 0 && (
            <div id="skills" className='skills'>
              <h1 className='title'>Skills</h1>
              <ul className='skill_list'>
                {userData.skills.map((skill, idx) => (
                  <li key={idx}>{skill}</li>
                ))}
              </ul>
              {getNextSection("skills") && <Arrow href={`#${getNextSection("skills")}`} />}
            </div>
          )}

          {userData.projects && userData.projects.length > 0 && (
            <div id="projects" className='recentProjects'>
              <h1 className='title'>Recent Projects</h1>
              {userData.projects.map((proj, idx) => (
                <div key={idx}>
                  <h2>{proj.name}</h2>
                  <p>{proj.description}</p>
                  {proj.link && (
                    <h4>
                      Link to project:{" "}
                      <a
                        href={
                          proj.link.startsWith("http")
                            ? proj.link
                            : `https://${proj.link}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click Here
                      </a>
                    </h4>
                  )}
                </div>
              ))}
              {getNextSection("projects") && <Arrow href={`#${getNextSection("projects")}`} />}
            </div>
          )}

          {userData.volunteering && userData.volunteering.length > 0 && (
            <div id="volunteering" className='volunteering'>
              <h1 className='title'>Volunteering</h1>
              {userData.volunteering.map((vol, idx) => (
                <div key={idx}>
                  <h2>{vol.organization}</h2>
                  <p>{vol.description}</p>
                </div>
              ))}
            </div>
          )}
          <a href="#top" className="back_to_top">Back to Top</a>
        </div>
      )}
    </>
  );
}
