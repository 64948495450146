import React, { useState, useEffect } from "react";
import { collection, getDocs, getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { auth } from '../config/firebase';

import edit from '../img/Edit.png' 
import back from '../img/Return.png'
import bin from '../img/Bin.png'

import '../css/Profile.css'

// Reusable DeleteButton component
function DeleteButton({ section, index, onDelete }) {
  const handleDelete = () => {
    const userConfirmed = window.confirm("Are you sure you want to delete this entry?");
    if (userConfirmed) {
        onDelete(section, index);
    }
};
  
    return (
      <button onClick={handleDelete} className="delete-button">
        <img alt="bin" src={bin}/>
      </button>
    );
  }

export function ProfileData() {
    const [formData, setFormData] = useState({
      name: "",
      phoneNumber: "",
      email: "",
      linkedInLink: "",
      resumeFile: "",
      url: "",
      description: "",
      experiences: [],
      education: [],
      skills: [],
      projects: [],
      volunteering: [],
    });

    const [urlInput, setUrlInput] = useState("");
    const [isLoading, setIsLoading] = useState(true); // Initial loading state
    const [isEditMode, setIsEditMode] = useState(true); // Initial edit mode state
    const [isUrlAvailable, setIsUrlAvailable] = useState(true);
  
  useEffect(() => {
    const fetchUserData = async (uid) => {
      const db = getFirestore();
      const userDoc = doc(db, "users", uid);
      const docSnap = await getDoc(userDoc);

      if (docSnap.exists()) {
        const fetchedData = docSnap.data();
        setFormData(prevState => ({
          ...prevState,
          ...fetchedData
        }));
        setIsEditMode(false)
      }
      setIsLoading(false); // Set loading to false once data is fetched
    };

    // Use an authentication state listener
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchUserData(user.uid);
      } else {
        setIsLoading(false); // Set loading to false if no user is authenticated
      }
    });

    // Cleanup the listener
    return () => unsubscribe();

  }, []);

// Function to handle URL validation
const handleUrlValidation = async (url, section, index) => {
    const db = getFirestore();
    const usersCollection = collection(db, "users");
    const querySnapshot = await getDocs(usersCollection);
  
    // Check if the URL already exists in the database, excluding the current user's URL
    const urlExists = querySnapshot.docs.some((doc) => {
      const userData = doc.data();
      return userData.url === url && doc.id !== auth.currentUser.uid;
    });
  
    setIsUrlAvailable(!urlExists);
  };  

const handleDescriptionChange = (e) => {
  const inputWords = e.target.value.split(/\s+/).filter(word => word); // Splitting by space and filtering out any empty strings
    if (inputWords.length <= 25) {
      setFormData({ ...formData, description: e.target.value });
  }
}

const handleAddExperience = () => {
    setFormData({
      ...formData,
      experiences: [...formData.experiences, { company: "", description: "" }],
    });
  };

  const handleAddEducation = () => {
    setFormData({
      ...formData,
      education: [...formData.education, { institute: "", description: "" }],
    });
  };

  // Create separate functions for adding skills, projects, and volunteering

  const handleAddSkill = () => {
    setFormData({
      ...formData,
      skills: [...formData.skills, ""],
    });
  };

  const handleAddProject = () => {
    setFormData({
      ...formData,
      projects: [...formData.projects, { name: "", description: "", link: ""  }],
    });
  };

  const handleAddVolunteering = () => {
    setFormData({
      ...formData,
      volunteering: [...formData.volunteering, { organization: "", description: "" }],
    });
  };

  const handleDeleteEntry = (section, index) => {
    const updatedSection = [...formData[section]];
    updatedSection.splice(index, 1);
    setFormData({ ...formData, [section]: updatedSection });
  };

  const handleInputChange = async (event, section, index) => {
    const { name, value } = event.target;
  
    if (name === "url") {
      setUrlInput(value); // Update the URL input field state
      await handleUrlValidation(value, section, index);
    } else {
      if (name === "description" && value.split(/\s+/).filter(word => word).length > 40) {
        // Limit the description to 40 words
        return;
      }
  
      const updatedSection = [...formData[section]];
      updatedSection[index][name] = value;
      setFormData({ ...formData, [section]: updatedSection });
    }
  };
  

  const handleEditModeToggle = () => {
    setIsEditMode((prevMode) => !prevMode);
    
    // Initialize the URL input field with the URL from the form data when entering edit mode
    if (!isEditMode) {
      setUrlInput(formData.url);
    }
  };

  const handleSubmit = async () => {
    if (!auth.currentUser) {
      console.error("No authenticated user");
      return;
    }

    // Check if the URL input is empty before submission
  if (urlInput.trim() === "") {
    // Prompt the user to provide the URL
    alert("Please provide a personal website URL extension.");
    return;
  }

    const db = getFirestore();
    // Ensure the uid is a string, as document paths must be strings
    const userDocRef = doc(db, "users", String(auth.currentUser.uid));
  
    try {
      await setDoc(userDocRef, {
        ...formData,
        url: urlInput, // Include the updated URL
      }, { merge: true }); // The merge: true option ensures existing data isn't overwritten
      console.log("Document successfully written or updated!");
      
      // Update the isEditMode to false after successful submission
      setIsEditMode(false);

    } catch (error) {
      console.error("Error writing document: ", error);
    }
};

  if (isLoading) {
    return (
      <div className="loading-container">
        <p>Loading your profile...</p>
      </div>
    );
  }

  return (
    <div className="profile_main">

    {isEditMode ? (
        <button onClick={handleEditModeToggle} style={{float:'right'}}>View Mode
            <img alt="back" src={back}/>
        </button>
      ) : (
        <button onClick={handleEditModeToggle} style={{float:'right'}}>Edit Mode
          <img alt="edit" src={edit}/>
        </button>
      )}

      <h2>Name</h2>
      {isEditMode ? (
        <input
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          placeholder="Name"
        />
      ) : (
        <p>{formData.name}</p>
      )}
      {/* Phone Number */}
      <h2>Phone Number</h2>
      {isEditMode ? (
        <input
          value={formData.phoneNumber}
          onChange={(e) =>
            setFormData({ ...formData, phoneNumber: e.target.value })
          }
          placeholder="Your phone number"
        />
      ) : (
        <p>{formData.phoneNumber}</p>
      )}

      {/* Email */}
      <h2>Email</h2>
      {isEditMode ? (
        <input
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          placeholder="Your email"
          type="email"
        />
      ) : (
        <p>{formData.email}</p>
      )}

      <h2>Personal Website URL</h2>

      {isEditMode ? (
        <div>
            <p>Choose your desired personal URL extension</p>
            <span style={{fontSize:'small', fontWeight:'400'}}>'Name' will look like 
            resumeurls.com/name</span>

            <div className="url_name">
          <p>resumeurls.com/</p><input
            value={urlInput}
            onChange={(e) => handleInputChange(e, "url")}
            name="url"
            placeholder="Your URL extension"
          />
          </div>

          {!isUrlAvailable && urlInput.trim() !== "" && (
            <p className="error-message" style={{color:'red'}}>This URL is already taken. Please choose another one.</p>
          )}
          {isUrlAvailable && urlInput.trim() !== "" && (
            <p className="error-message" style={{color:'green'}}>This URL is available.</p>
          )}
        </div>
      ) : (
        <a href={`https://resumeurls.com/${formData.url}`} target="_blank" rel="noopener noreferrer">resumeurls.com/{formData.url}</a>
      )}


    <h2>LinkedIn Link</h2>
    {isEditMode ? (
        <input
            value={formData.linkedInLink}
            onChange={(e) => setFormData({ ...formData, linkedInLink: e.target.value })}
            placeholder="Your LinkedIn Link"
        />
    ) : (
        <a href={formData.linkedInLink} target="_blank" rel="noopener noreferrer">{formData.linkedInLink}</a>
    )}

            {/* Description */}
            <h2>About <span style={{fontSize:'small', fontWeight:'400'}}>(max 25 words)</span></h2>
        {isEditMode ? (
          <textarea
            value={formData.description}
            onChange={handleDescriptionChange}
            placeholder="Short description about yourself"
            rows="3"
          />
        ) : (
          <p>{formData.description}</p>
        )}

      <h2>Experiences</h2>
      {formData.experiences.map((exp, idx) => (
        <div key={idx} className="list_data">
          {isEditMode ? (
            <div>
              <input
                value={exp.company}
                onChange={(e) => handleInputChange(e, "experiences", idx)}
                name="company"
                placeholder="Company Name"
              />
              <textarea
                value={exp.description}
                onChange={(e) => handleInputChange(e, "experiences", idx)}
                name="description"
                placeholder="Experience Description | Recommended max of 30 words"
              />
            </div>
          ) : (
            <div>
              <p>Company: {exp.company}</p>
              <p>Description: {exp.description}</p>
            </div>
          )}
          {isEditMode && (
            <DeleteButton
              section="experiences"
              index={idx}
              onDelete={handleDeleteEntry}
            />
          )}
        </div>
      ))}
      {isEditMode && (
        <button onClick={handleAddExperience} className="add_new">Add Experience</button>
      )}

      <h2>Education</h2>
      {formData.education.map((edu, idx) => (
        <div key={idx} className="list_data">
          {isEditMode ? (
            <div>
              <input
                value={edu.institute}
                onChange={(e) => handleInputChange(e, "education", idx)}
                name="institute"
                placeholder="Institute Name"
              />
              <textarea
                value={edu.description}
                onChange={(e) => handleInputChange(e, "education", idx)}
                name="description"
                placeholder="Education Description | Recommended max of 30 words"
              />
            </div>
          ) : (
            <div>
              <p>Institute: {edu.institute}</p>
              <p>Description: {edu.description}</p>
            </div>
          )}
          {isEditMode && (
            <DeleteButton
              section="education"
              index={idx}
              onDelete={handleDeleteEntry}
            />
          )}
        </div>
      ))}
      {isEditMode && (
        <button onClick={handleAddEducation} className="add_new">Add Education</button>
      )}

      {/* Skills */}
      <h2>Skills</h2>
      {formData.skills.map((skill, idx) => (
        <div key={idx} className="list_data">
          {isEditMode ? (
            <input
              value={skill}
              onChange={(e) => {
                const updatedSkills = [...formData.skills];
                updatedSkills[idx] = e.target.value;
                setFormData({ ...formData, skills: updatedSkills });
              }}
              placeholder="Skill"
              className="skill"
            />
          ) : (
            <p>Skill: {skill}</p>
          )}
          {isEditMode && (
            <DeleteButton
              section="skills"
              index={idx}
              onDelete={handleDeleteEntry}
            />
          )}
        </div>
      ))}
      {isEditMode && <button onClick={handleAddSkill} className="add_new">Add Skill</button>}

      {/* Projects */}
      <h2>Recent Projects</h2>
      {formData.projects.map((proj, idx) => (
        <div key={idx} className="list_data">
          {isEditMode ? (
            <div>
              <input
                value={proj.name}
                onChange={(e) => handleInputChange(e, "projects", idx)}
                name="name"
                placeholder="Project Name"
              />
              <textarea
                value={proj.description}
                onChange={(e) => handleInputChange(e, "projects", idx)}
                name="description"
                placeholder="Project Description | Recommended max of 30 words"
              />
                    <input
          value={proj.link}
          onChange={(e) => handleInputChange(e, "projects", idx, "link")}
          name="link"
          placeholder="ProjectLink.com"
        />
      </div>
    ) : (
      <div>
        <p>Project Name: {proj.name}</p>
        <p>Description: {proj.description}</p>
        {proj.link && (  // Add a check for proj.link
          <p>Link: <a href={proj.link.startsWith('http')
          ? proj.link : `https://${proj.link}`} target="_blank"
          rel="noopener noreferrer">Click Here</a></p>
        )}
      </div>
          )}
          {isEditMode && (
            <DeleteButton
              section="projects"
              index={idx}
              onDelete={handleDeleteEntry}
            />
          )}
        </div>
      ))}
      {isEditMode && (
        <button onClick={handleAddProject} className="add_new">Add Project</button>
      )}

      {/* Volunteering */}
      <h2>Volunteering</h2>
      {formData.volunteering.map((vol, idx) => (
        <div key={idx} className="list_data">
          {isEditMode ? (
            <div>
              <input
                value={vol.organization}
                onChange={(e) => handleInputChange(e, "volunteering", idx)}
                name="organization"
                placeholder="Organization Name"
              />
              <textarea
                value={vol.description}
                onChange={(e) => handleInputChange(e, "volunteering", idx)}
                name="description"
                placeholder="Volunteering Description | Recommended max of 30 words"
              />
            </div>
          ) : (
            <div>
              <p>Organization Name: {vol.organization}</p>
              <p>Description: {vol.description}</p>
            </div>
          )}
          {isEditMode && (
            <DeleteButton
              section="volunteering"
              index={idx}
              onDelete={handleDeleteEntry}
            />
          )}
        </div>
      ))}
      {isEditMode && (
        <button onClick={handleAddVolunteering} className="add_new">Add Volunteering</button>
      )}

      {isEditMode && (
      <button onClick={handleSubmit} className="submit">Submit</button>)}
    
    </div>
  );
}