import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { PersonalWebsite } from './comps/personalWebsite';
import { Home } from './comps/Home';
import { Login } from './comps/Login';
import { Profile } from './comps/Profile';
import { Footer } from './comps/Footer';
import { Nav } from './comps/Nav';
import { NotFound } from './comps/NotFound';
import { About } from './comps/About';
import { HomeDesktopView } from './comps/HomeDesktop';
import { isMobile } from 'react-device-detect';

function App() {
  const [showNav, setShowNav] = useState(true);
  const [showFooter, setShowFooter] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    setIsMobileView(isMobile);

    const qualifyingPaths = ['/', '/login', '/profile', '/about'];
    const currentPath = window.location.pathname.toLowerCase();

    if (!isMobile && qualifyingPaths.includes(currentPath) && currentPath !== '/desktopversion') {
      window.location.href = '/desktopversion';
    } else if (isMobile && currentPath === '/desktopversion') {
      window.location.href = '/';
    }
  }, []);

  return (
    <BrowserRouter>
      <div className={`app-wrapper ${!isMobileView && window.location.pathname === '/' ? 'body_main' : ' desktop-view'}`}>
        {showNav && <Nav />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/DesktopVersion" element={<HomeDesktopView />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/About" element={<About />} />
          <Route path="/:url" element={<PersonalWebsite
            setShowNav={() => setShowNav(false)}
            setShowFooter={setShowFooter}
          />} />
          <Route path="*" element={<NotFound />} />
          <Route path="404" element={<NotFound />} />
        </Routes>
        {showFooter && <Footer />}
      </div>
    </BrowserRouter>
  );
}

export default App;
