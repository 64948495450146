import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// Define the root element
const rootElement = document.getElementById('root'); // Change 'root' to your actual root element ID

const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
