import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <div className='defult'>
      <h1>404 - Not Found</h1>
      <h2>The page you're looking for doesn't exist 😕</h2>

      <a href="/" alt="resume Urls">Go back to Resume Urls</a>
    </div>
  );
};
