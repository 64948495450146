import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../config/firebase';
import '../css/Nav.css';

export function Nav() {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const isLoggedIn = !!auth.currentUser; 

    const closeMenu = () => {
        if (isMobileMenuOpen) {
            setMobileMenuOpen(false);
        }
    };

    return (
        <nav className="navbar">
            <button 
                className={`${isMobileMenuOpen ? 'x-menu' : 'hamburger-menu'}`}
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            >
                {isMobileMenuOpen ? '✖' : '☰'}
            </button>

            <a href='/' style={{textDecoration:"none", width:"80%"}}>
                <span className='head'>Resume Urls</span>
            </a>

            <ul className={`nav-list ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                <li><a href="/" onClick={closeMenu}>Home</a></li>
                <li><Link to="/about" onClick={closeMenu}>About</Link></li>
                {isLoggedIn ? (
                    <li><Link to="/profile" onClick={closeMenu}>Profile</Link></li>
                ) : (
                    <li><Link to="/login" onClick={closeMenu}>Login</Link></li>
                )}
            </ul>
        </nav>
    );
}
