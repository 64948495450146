import '../css/Arrow.css'; // Assuming you save the above CSS in ArrowDown.css

export function Arrow({ href }) {
    return (
      <a href={href} className="arrow-link">
        <div className="arrow">
            <span/>
            <span/>
        </div>
      </a>
    );
  }
