import '../css/About.css'
export const About = () => {
  return (
    <div className="content">
      <h1 className='about_h1'>About Us</h1>
      <p className='about_p'>
        Hi there 👋, 
        <br/>
        I'm Ofri, the creator behind Resume URLs.
        <br/>
        I built this platform to help showcasing your background and
        skills uniquely and my goal is to help you ace the initial
        stage of your job search effortlessly.
      </p>
      <h3 className='about_h3'>My Story</h3>
      <p className='about_p'>
      As someone currently seeking job opportunities,
      I truly understand the value of standing out from the crowd.<br/>
      Throughout my journey, I've realized the pivotal role of a distinctive
      online presence in today's competitive landscape.<br/>
      Crafting a personal website has become a crucial tool for me
      to make a memorable impression and pave the way for potential opportunities.
      </p>
      <h3 className='about_h3'>Why Resume URLs?</h3>
      <p className='about_p'>
        I developed Resume URLs to help job seekers easily and uniquely display their skills,
        making the first step of job hunting smoother.
      </p>
      <h3 className='about_h3'>Join Me</h3>
      <p className='about_p'>
        Join professionals who stand out.<br/>
        Create your distinctive web-resume with Resume URLs and conquer your career
        journey.
      </p>
    </div>
  );
};

