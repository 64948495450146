import Lottie from 'lottie-react';
import loading from '../img/Loading.json'; 

export const Loading = () => {
    
  return (
    <div style={{height:'100vh'}}>
      <Lottie animationData={loading} autoplay loop />
    </div>
  );
};

