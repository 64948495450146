import React from 'react';
import '../css/ProfileDashboard.css';

export function ProfileDashboard() {
    const visitorsCount = 99;

    return (
        <div className="dashboard">
               <h1>Work in Progress👷</h1>
            {/* <div  className="box">
                <div className="counter">{visitorsCount}</div>
                <span className="label">resume visitors</span>
            </div> */}
        </div>
    );
}

