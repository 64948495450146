import React, { useState, useEffect } from 'react';
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore"; 
import { auth } from '../config/firebase'; 
import '../css/ProfileTheme.css';
import '../css/PersonalWebsite.css';

function ColorOption({ currentSelected, colorValue, colorType, onSelect }) {
    const style = colorType === 'background'
        ? { backgroundImage: `var(${colorValue})` }
        : { backgroundColor: `var(${colorValue})` };

    return (
        <div 
            className={`color-square ${currentSelected === colorValue && 'active'}`}
            style={style}
            onClick={() => onSelect(colorValue, colorType)}
        ></div>
    );
}

export function ProfileTheme() {
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedTextColor, setSelectedTextColor] = useState('');
    const [previewStyle, setPreviewStyle] = useState({});

    useEffect(() => {
        const updatedPreviewStyle = {
            background: `var(${selectedColor})`,
            color: `var(${selectedTextColor})`
        };
        setPreviewStyle(updatedPreviewStyle);

    }, [selectedColor, selectedTextColor]);

    const handleColorSelect = (colorValue, colorType) => {
        if (colorType === 'background') {
            setSelectedColor(colorValue);
        } else if (colorType === 'text') {
            setSelectedTextColor(colorValue);
        }
    }
    
    const handleSaveClick = () => {
        saveSelectedColor(selectedColor);
    }

    const saveSelectedColor = async () => {
        if (!auth.currentUser) {
            console.error("No authenticated user");
            return;
        }
    
        const db = getFirestore();
        const userDocRef = doc(db, "users", String(auth.currentUser.uid));
    
        try {
            await setDoc(userDocRef, {
                selectedBackgroundColor: selectedColor,
                selectedTextColor: selectedTextColor
            }, { merge: true });
            console.log("Selected colors saved successfully!");
        } catch (error) {
            console.error("Error saving selected colors: ", error);
        }
    }
    
    useEffect(() => {
        const fetchUserColor = async (uid) => {
            const db = getFirestore();
            const userDoc = doc(db, "users", uid);
            const docSnap = await getDoc(userDoc);
    
            if (docSnap.exists()) {
                const fetchedData = docSnap.data();
                if(fetchedData.selectedBackgroundColor) {
                    setSelectedColor(fetchedData.selectedBackgroundColor);
                }
                if(fetchedData.selectedTextColor) {
                    setSelectedTextColor(fetchedData.selectedTextColor);
                }
            }
        };

        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                fetchUserColor(user.uid);
            }
        });

        return () => unsubscribe();

    }, []);

    return (
        <div className="profile-theme">
            
            <h2>Customize your website</h2>
    
            <h3>Pick a background color</h3>
            <div className='Backgrounds'>
                <ColorOption 
                    currentSelected={selectedColor}
                    colorValue='--blue_green_gardient'
                    colorType='background'
                    onSelect={handleColorSelect}
                />
                <ColorOption 
                    currentSelected={selectedColor}
                    colorValue='--no_back_image'
                    colorType='background'
                    onSelect={handleColorSelect}
                />
                <ColorOption 
                    currentSelected={selectedColor}
                    colorValue='--white_gardient'
                    colorType='background'
                    onSelect={handleColorSelect}
                />
                <ColorOption 
                    currentSelected={selectedColor}
                    colorValue='--blue_purple_gardient'
                    colorType='background'
                    onSelect={handleColorSelect}
                />
                                <ColorOption 
                    currentSelected={selectedColor}
                    colorValue='--black_gardient'
                    colorType='background'
                    onSelect={handleColorSelect}
                />
            </div>
    
            <h3>Pick text color</h3>
            <div className='TextColors'>
                <ColorOption 
                    currentSelected={selectedTextColor}
                    colorValue='--black'
                    colorType='text'
                    onSelect={handleColorSelect}
                />
                <ColorOption 
                    currentSelected={selectedTextColor}
                    colorValue='--white'
                    colorType='text'
                    onSelect={handleColorSelect}
                />
            </div>

                <div>
                    <h3>Preview</h3>
                        <div className='box' style={previewStyle}>
                            <span className='theme_text'>
                                Your Text
                            </span>
                        </div>
                </div>
            <button onClick={handleSaveClick} className='build'>Save Selected Colors</button>
        </div>
    );
    
}

