import { useEffect } from "react";
import { isMobile } from 'react-device-detect';

export function Home() {

  useEffect(() => {
    if (isMobile) {
      // If it's a mobile device, add the class to the body
      document.body.classList.add('body_main');
    }else{
      document.body.classList.add('body_main');
    }
  }, []);
  
  
  return (
    <div className="defult"> 
      <div className="content">
        <h1>Craft your online resume in minutes</h1>
        <h3>
        Create your personal and professional web-resume
        and share it with employers to secure your next opportunity
        </h3>

        <a href="/Login" className='build' style={{textDecoration:"none"}}>
          <button className="build">Craft your web resume now</button>
        </a>
        <a href="https://resumeurls.com/exampleprofile"
        alt="resume example" 
        style={{textDecoration:"none", color:'#333', fontSize:"small", marginLeft:"10px"}}
        target="blank">Or Check out this <b>live</b> resume</a>

      </div>
    
      <div className="next-section">
        {/* <img src="your_image_url.jpg" alt="Description of the image" /> */}
       
        <p className="main_p">
        Showcase your skills and experience in a
        professional way with Resume URLs.
        <br/>
        <br/>
        Create a stunning resume website effortlessly and
        stand out from the crowd.
        <br/>
        <br/>
        Build your online presence 
        in minutes and let your accomplishments shine!
        </p>
        
        <h3>With Resume Urls You can Share</h3>
        <ul className="resume_list">
          <li>Short introduction </li>
          <li>Experience</li>
          <li>Education</li>
          <li>Skills</li>
          <li>Personal Projects</li>
          <li>Volunteering</li>
          <li>Useful Links</li>
          <li>Contact information</li>
        </ul>
        
      </div>
    </div>
  );
}
