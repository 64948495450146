import { useState } from 'react';
import { ProfileData } from './ProfileData.js'
import { ProfileTheme } from './ProfileTheme.js';
import { ProfileDashboard } from './ProfileDashboard.js';
import { Navigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import '../css/Profile.css'

export function Profile() {
    const [activeTab, setActiveTab] = useState('tab1');
    const [loggedOut, setLoggedOut] = useState(false);

    const handleLogout = async () => {
        try {
            await auth.signOut();
            console.log('Successfully logged out!');
            localStorage.removeItem('isLoggedIn');
            setLoggedOut(true);
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    if (loggedOut) {
        return <Navigate to="/login" />;
    }
    return (
        <div>

            <div className="tabs">
                <button 
                    className={activeTab === 'tab1' ? 'on' : ''}
                    onClick={() => setActiveTab('tab1')}>
                    Data
                </button>
                <button 
                    className={activeTab === 'tab2' ? 'on' : ''}
                    onClick={() => setActiveTab('tab2')}>
                    Theme
                </button>
                <button 
                    className={activeTab === 'tab3' ? 'on' : ''}
                    onClick={() => setActiveTab('tab3')}>
                    Dashboard
                </button>

            </div>
            
            {activeTab === 'tab1' && <ProfileData />}
            {activeTab === 'tab2' && <ProfileTheme />}
            {activeTab === 'tab3' && <ProfileDashboard />}

            <button onClick={handleLogout} className='logout'>
                Logout</button>
        </div>
    );
}
