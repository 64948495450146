import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCUk3SNFWBoeF6I7YyxpXUw5jrA4a-8ceI",
    authDomain: "resumeurl-7a425.firebaseapp.com",
    projectId: "resumeurl-7a425",
    storageBucket: "resumeurl-7a425.appspot.com",
    messagingSenderId: "300430584229",
    appId: "1:300430584229:web:840e19118c270e59eca04c",
    measurementId: "G-S07PKPYKHK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
